<!--
 * @Description: 流程字段表单设置
 * @Author: 琢磨先生
 * @Date: 2022-09-11 04:28:13
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-18 21:40:37
-->
<template>
  <el-row class="row_body workflow" :gutter="20">
    <el-col :span="8" class="left">
      <el-scrollbar>
        <div class="box">
          <div class="box_header">基础组件</div>
          <div class="box_body">
            <draggable
              class="form_group"
              v-model="components"
              :clone="onClone"
              :group="{ name: 'wk', pull: 'clone', put: false }"
              :sort="false"
              item-key="name"
            >
              <template #item="{ element }">
                <div :class="`item ${element.element}`">
                  <div class="label">{{ element.label }}</div>
                  <div class="input_wrp">
                    <div
                      class="input_textarea"
                      v-if="element.element=='textarea'"
                    >{{element.placeholder}}</div>

                    <el-radio-group v-if="element.element=='radio'">
                      <el-radio
                        :label="item.name"
                        v-for="item in element.items"
                        :key="item"
                      >{{item.name}}</el-radio>
                    </el-radio-group>

                    <el-checkbox-group v-if="element.element=='checkbox'">
                      <el-checkbox label="Option A" />
                      <el-checkbox label="Option B" />
                      <el-checkbox label="Option C" />
                    </el-checkbox-group>

                    <div
                      class="placeholder"
                      v-if="element.element=='text' || element.element=='email' || element.element=='number' || element.element=='url'"
                    >{{ element.placeholder }}</div>

                    <div
                      v-if="element.element=='time' || element.element=='date' || element.element=='select'"
                    >
                      <el-icon>
                        <ArrowDown />
                      </el-icon>
                    </div>
                    <div class="upload" v-if="element.element=='file'">
                      <el-icon class="uploader-icon">
                        <Plus />
                      </el-icon>
                    </div>
                    <div class="upload" v-if="element.element=='image'">
                      <el-icon class="uploader-icon">
                        <Plus />
                      </el-icon>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </el-scrollbar>
    </el-col>
    <el-col :span="8" class="middle">
      <section class="media">
        <el-scrollbar>
          <draggable
            class="form_group dragArea"
            v-model="form_components"
            :group="{ name: 'wk', pull: 'clone', put: true }"
            @change="onChange"
            item-key="name"
          >
            <template #item="{ element, index }">
              <div
                :class="`item ${element.element} ${element.is_checked?'is_checked':''} ${element.is_required?'is_required':''}`"
                @click="onItemClick(index)"
              >
                <div class="label">{{ element.label }}</div>
                <div class="input_wrp">
                  <div
                    class="input_textarea"
                    v-if="element.element=='textarea'"
                  >{{element.placeholder}}</div>

                  <el-radio-group v-if="element.element=='radio'">
                    <el-radio
                      :label="item.name"
                      v-for="item in element.items"
                      :key="item"
                    >{{item.name}}</el-radio>
                  </el-radio-group>

                  <el-checkbox-group v-if="element.element=='checkbox'">
                    <el-checkbox
                      :label="item.name"
                      v-for="item in element.items"
                      :key="item"
                    >{{item.name}}</el-checkbox>
                  </el-checkbox-group>

                  <div
                    class="placeholder"
                    v-if="['currency','text','email','number','url'].find(o=>o==element.element)"
                  >{{ element.placeholder }}</div>

                  <div
                    class="placeholder"
                    v-if="element.element=='time' || element.element=='date' || element.element=='select' || element.element=='datetime'"
                  >
                    <el-icon v-if="element.element=='date'">
                      <Calendar />
                    </el-icon>
                    <el-icon v-if="element.element=='time' || element.element=='datetime'">
                      <Clock />
                    </el-icon>
                    {{ element.placeholder }}
                    <el-icon v-if="element.element=='select'">
                      <ArrowDown />
                    </el-icon>
                  </div>
                  <div class="upload" v-if="element.element=='file'">
                    <el-icon class="uploader-icon">
                      <Plus />
                    </el-icon>
                  </div>
                  <div class="upload" v-if="element.element=='image'">
                    <el-icon class="uploader-icon">
                      <Plus />
                    </el-icon>
                  </div>
                </div>
                <div class="input_del" @click.stop="onRemove(index)">
                  <el-icon>
                    <Delete />
                  </el-icon>
                </div>
              </div>
            </template>
          </draggable>
        </el-scrollbar>
      </section>
    </el-col>
    <el-col :span="8">
      <div class="property_box">
        <div class="title">字段属性</div>
        <div class="property_body">
          <el-scrollbar>
            <el-form :model="form" ref="form" label-width="100px" v-if="form.element">
              <el-form-item label="控件类型">{{form.element_name}}</el-form-item>
              <el-form-item label="标题">
                <el-input v-model="form.label"></el-input>
              </el-form-item>
              <el-form-item label="提示文字" v-if="!['radio','checkbox'].find(o=>o==form.element)">
                <el-input v-model="form.placeholder"></el-input>
              </el-form-item>
              <el-form-item label="是否必填">
                <el-checkbox v-model="form.is_required"></el-checkbox>
              </el-form-item>
              <el-form-item label="最少字数" v-if="form.element=='text' || form.element=='textarea'">
                <el-input v-model="form.minLength"></el-input>
              </el-form-item>
              <el-form-item label="最大字数" v-if="form.element=='text' || form.element=='textarea'">
                <el-input v-model="form.maxlength"></el-input>
              </el-form-item>

              <el-form-item label="最小值" v-if="form.element=='number'">
                <el-input v-model="form.min"></el-input>
              </el-form-item>
              <el-form-item label="最大值" v-if="form.element=='number'">
                <el-input v-model="form.max"></el-input>
              </el-form-item>

              <el-form-item
                label="选项"
                v-if="['radio','checkbox','select'].find(o=>o==form.element)"
              >
                <div style="width:100%">
                  <div class="row_options" v-for="(x,i) in form.items" :key="x">
                    <div class="option_input">
                      <el-input v-model="x.name" placeholder></el-input>
                    </div>
                    <div class="option_close">
                      <el-button
                        type="danger"
                        v-if="form.items.length>1"
                        link
                        @click="form.items.splice(i,1)"
                      >
                        <el-icon>
                          <Close />
                        </el-icon>
                      </el-button>
                    </div>
                  </div>
                </div>
                <div>
                  <el-button
                    type="primary"
                    link
                    icon="Plus"
                    @click="form.items.push({name:'新选项',value:''})"
                  >添加选项</el-button>
                </div>
              </el-form-item>
              <el-form-item label="多文件" v-if="['file','image'].find(o=>o==form.element)">
                <el-checkbox v-model="form.is_multiple"></el-checkbox>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },
  data() {
    return {
      form: {},
      schema: {
        type: "object",
        required: ["userName", "age"],
        properties: {
          userName: {
            type: "string",
            title: "用户名",
            default: "Liu.Jun",
          },
          age: {
            type: "number",
            title: "年龄",
          },
          bio: {
            type: "string",
            title: "签名",
            minLength: 10,
            default: "知道的越多、就知道的越少",
            "ui:options": {
              placeholder: "请输入你的签名",
              type: "textarea",
              rows: 1,
            },
          },
        },
      },
      //组件
      components: [],
      //表单组件
      form_components: [],
      //点击选中
      cbxIndex: 0,
    };
  },
  props: ["model"],
  /**0
   *
   */
  created() {
    this.initData();
  },
  watch: {
    model: {
      handler() {
        if (this.model && this.model.form_list) {
          this.form_components = JSON.parse(
            JSON.stringify(this.model.form_list)
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 初始化数据
     */
    initData() {
      this.components.push({
        label: "单行文本框",
        placeholder: "请输入",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "text",
        element_name: "单行文本框",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "多行文本框",
        placeholder: "请输入",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "textarea",
        element_name: "多行文本框",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "单选框组",
        placeholder: "",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [
          { name: "Option A", value: "" },
          { name: "Option B", value: "" },
          { name: "Option C", value: "" },
        ],
        element: "radio",
        element_name: "单选框组",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "多选框组",
        placeholder: "",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [
          { name: "Option A", value: "" },
          { name: "Option B", value: "" },
          { name: "Option C", value: "" },
        ],
        element: "checkbox",
        element_name: "多行文本框",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "金额",
        placeholder: "请输入金额",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "currency",
        element_name: "货币金额输入框",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "计数器",
        placeholder: "",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "number",
        element_name: "整数输入框",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "邮箱地址",
        placeholder: "请输入邮箱地址",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "email",
        element_name: "邮箱地址输入框",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "下拉选择框",
        placeholder: "请选择",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [
          { name: "Option A", value: "" },
          { name: "Option B", value: "" },
          { name: "Option C", value: "" },
        ],
        element: "select",
        element_name: "下拉选择框",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "Url地址",
        placeholder: "请输入网址",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "url",
        element_name: "Url输入框",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "日期与时间",
        placeholder: "请选择",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "datetime",
        element_name: "年月日时分选择",
        default_value: "",
        component: "",
        is_multiple: false,
      });

      this.components.push({
        label: "日期选择",
        placeholder: "请选择",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "date",
        element_name: "年月日",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "时间选择",
        placeholder: "请选择",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "time",
        element_name: "时分选择",
        default_value: "",
        component: "",
        is_multiple: false,
      });
      this.components.push({
        label: "上传文件",
        placeholder: "",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "file",
        element_name: "文件上传控件",
        default_value: "",
        component: "",
        is_multiple: false,
      });

      this.components.push({
        label: "上传图片",
        placeholder: "",
        type: 0,
        is_required: false,
        is_range: false,
        max: "",
        min: "",
        maxlength: "",
        minlength: "",
        items: [],
        element: "image",
        element_name: "图片上传控件",
        default_value: "",
        component: "",
        is_multiple: false,
      });
    },
    /**
     * 表单验证，并返回 验证结果和表单数据
     */
    validate(fn) {
      if (this.form_components.length > 0) {
        fn({
          valid: true,
          form: this.form_components,
        });
      } else {
        this.$message.error("请添加字段");
      }
    },
    /**
     * 克隆对象
     */
    onClone(original) {
      return JSON.parse(JSON.stringify(original));
    },
    /**
     *
     */
    onChange() {
      if (this.form_components.length == 1) {
        this.onItemClick(0);
      }
    },
    /**
     *
     * 点击
     */
    onItemClick(i) {
      this.form_components.forEach((item) => {
        item.is_checked = false;
      });

      this.form_components[i].is_checked = true;
      this.form = this.form_components[i];
    },
    /**
     * 移除
     */
    onRemove(i) {
      var item = this.form_components.splice(i, 1)[0];
      if (item.is_checked) {
        if (this.form_components.length > i) {
          this.onItemClick(i);
        } else if (this.form_components.length > 0) {
          this.onItemClick(i - 1);
        } else {
          this.form = {};
        }
      }
    },
  },
};
</script>

<style  scoped>
.row_body {
  height: 100%;
  flex-grow: 1;
}

.left {
  height: 100%;
  flex: 1;
}
.left .box {
  margin-bottom: 20px;
  padding-right: 20px;
}
.left .box .box_header {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}
.left .box .box_body {
  width: 100%;
}
.left .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* column-gap: 10px; */
  row-gap: 20px;
}

.middle {
  display: flex;
  justify-content: center;
}
.media {
  height: 100%;
  width: 380px;
  border-radius: 10px;
  border: 5px solid #000;
}

.media .dragArea {
  height: 60vh;
}

/* 表单组件 */
.form_group .item {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid var(--el-border-color);
  position: relative;
  cursor: move;
}
.form_group .item:hover {
  background: #d9ecff;
}
.form_group .item.is_required::before {
  content: "*";
  color: red;
  display: block;
  position: absolute;
  margin-left: -8px;
  margin-top: 6px;
  /* display: inline-block; */
}
.left .form_group .item {
  margin-bottom: 20px;
  border-radius: 4px;
}
.middle .form_group .item {
  border: none;
  border-bottom: 1px solid var(--el-border-color);
}

.form_group .item .label {
  width: 30%;
}
.form_group .item .input_wrp {
  flex-grow: 1;
  width: 1px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.form_group .item .input_wrp .placeholder {
  color: #a8abb2;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form_group .item .input_del {
  position: absolute;
  right: 0;
  top: 0;
  background: #f89898;
  width: 25px;
  height: 25px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  visibility: hidden;
}

.form_group .item:hover .input_del {
  visibility: visible;
}

.form_group .item.is_checked {
  background: #f3d19e;
}

.form_group .item.textarea,
.form_group .item.image,
.form_group .item.file {
  flex-direction: column;
  align-items: flex-start;
}
.form_group .item.textarea .input_wrp {
  height: 50px;
}
.form_group .item.textarea .input_wrp,
.form_group .item.image .input_wrp,
.form_group .item.file .input_wrp {
  margin-top: 5px;
  width: 100%;
  display: block;
  color: #999;
}

.form_group .upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  display: inline-block;
}
.form_group .el-icon.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
}

/* 编辑属性 */
.property_box {
  border: 1px solid var(--el-border-color);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.property_box .title {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--el-border-color);
}
.property_box .property_body {
  height: 1px;
  flex-grow: 1;
}
.property_box .row_options {
  display: flex;
  align-items: center;
  margin: 5px;
}
.property_box .row_options .option_input {
  width: 80%;
  margin-right: 10px;
}
</style>


<style>
.property_box .el-form {
  padding: 0 15px;
  margin-top: 20px;
}
.property_box .el-form-item {
  border-bottom: 1px solid var(--el-border-color);
  padding-bottom: 20px;
}
.property_box .el-form-item__label {
  justify-content: flex-start;
}
</style>
 